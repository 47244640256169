import { Injectable } from "@angular/core";
import { SpeedCatchup } from "@common/ADAPT.Common.Model/organisation/speed-catchup";
import { CatchupRatingType } from "@common/ADAPT.Common.Model/organisation/speed-catchup-rating";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { switchMap, tap } from "rxjs/operators";
import { CatchupHistoryChartDialogComponent } from "./catchup-history-chart-dialog/catchup-history-chart-dialog.component";
import { myCatchupsPageRoute, personalCatchupsPageRoute } from "./my-catchups-page/my-catchups-page.route";
import { PeerCatchupService } from "./peer-catchup.service";
import { RecordCatchupDialogComponent } from "./record-catchup-dialog/record-catchup-dialog.component";
import { ShowCatchupDialogComponent } from "./show-catchup-dialog/show-catchup-dialog.component";

@Injectable({
    providedIn: "root",
})
export class PeerCatchupUiService {
    public constructor(
        private dialogService: AdaptCommonDialogService,
        private catchupService: PeerCatchupService,
    ) { }

    public showCatchup(
        catchup: SpeedCatchup,
        hideHistoryButton?: boolean,
        enterEditMode?: boolean,
        scrollToFacilitator?: boolean,
        scrollToRatingTypeName?: CatchupRatingType,
    ) {
        return this.dialogService.open(ShowCatchupDialogComponent, {
            catchup,
            hideHistoryButton,
            enterEditMode,
            showFacilitatorSection: scrollToFacilitator,
            focusRatingType: scrollToRatingTypeName,
        });
    }

    public showCatchupHistoryChart(person1: Person, person2: Person) {
        return this.dialogService.open(CatchupHistoryChartDialogComponent, { person1, person2 });
    }

    public deleteCatchup(catchup: SpeedCatchup) {
        return this.dialogService.openConfirmationDialog({
            title: "Delete Peer Catch-up",
            message: `Are you sure you wish to delete this Peer Catch-up between ${catchup.person1!.fullName} & ${catchup.person2!.fullName}?`,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        }).pipe(
            switchMap(() => this.catchupService.remove(catchup)),
            switchMap(() => this.catchupService.saveEntities(catchup)),
            tap(() => this.catchupService.emitCatchupChange(catchup)), // detached entity will be emitted
        );
    }

    public recordNewCatchup(person1Id: number, person2Id?: number, teamId?: number) {
        // create catchup outside of record dialog to make restoring unsaved entity easier -> entity restore before passed into the dialog
        // component
        return this.catchupService.createNewCatchup(person1Id, person2Id, teamId).pipe(
            switchMap((catchup) => this.dialogService.open(RecordCatchupDialogComponent, { catchup })),
            tap((savedCatchup) => this.catchupService.emitCatchupChange(savedCatchup)),
        );
    }

    public getDashboardUrl(person?: Person) {
        return person
            ? personalCatchupsPageRoute.getRoute({ personId: person.personId })
            : myCatchupsPageRoute.getRoute();
    }
}
