<div adaptToolbarContent>
    <a [routerLink]="personalDashboardLink$ | async"
       adaptButton="dashboardLink"
       adaptTooltip="Go to personal dashboard"></a>
</div>

<adapt-configuration-page-base [configGroups]="configGroups"
                               configTitle="My Configuration"
                               (selectedItemChange)="selectedItem = $event">
    <adapt-configure-personal-kanban *ngIf="selectedItem?.area === ConfigArea.Kanban"
                                     [configItem]="selectedItem">
    </adapt-configure-personal-kanban>
    <adapt-configure-calendar-integration *ngIf="selectedItem?.area === ConfigArea.CalendarIntegration"
                                          [configItem]="selectedItem"
                                          [personalConfig]="true"></adapt-configure-calendar-integration>
    <adapt-configure-personal-single-sign-on *ngIf="selectedItem?.area === ConfigArea.SingleSignOn"
                                             [configItem]="selectedItem"></adapt-configure-personal-single-sign-on>
    <adapt-configure-personal-notifications *ngIf="selectedItem?.area === ConfigArea.Notifications"
                                            [configItem]="selectedItem">
    </adapt-configure-personal-notifications>
</adapt-configuration-page-base>
