<div *ngIf="isFullLayout$ | async; else verticalLayoutTemplate"
     class="zone-map-full-layout">
    <div class="not-org-design">
        <ng-container *ngTemplateOutlet="zoneDisplayTemplate; context: {$implicit: Zone.EconomicEngine}"></ng-container>

        <div class="not-economic-engine">
            <ng-container *ngTemplateOutlet="zoneDisplayTemplate; context: {$implicit: Zone.OrganisationDesign}"></ng-container>
            <ng-container *ngTemplateOutlet="zoneDisplayTemplate; context: {$implicit: Zone.HealthyCulture}"></ng-container>
            <ng-container *ngTemplateOutlet="zoneDisplayTemplate; context: {$implicit: Zone.FinanceAndOwnership}"></ng-container>
        </div>
    </div>

    <ng-container *ngTemplateOutlet="globalZoneTemplate"></ng-container>
</div>

<ng-template #verticalLayoutTemplate>
    <div class="zone-map-vertical-layout">
        <ng-container *ngTemplateOutlet="zoneDisplayTemplate; context: {$implicit: Zone.EconomicEngine}"></ng-container>
        <ng-container *ngTemplateOutlet="zoneDisplayTemplate; context: {$implicit: Zone.OrganisationDesign}"></ng-container>
        <ng-container *ngTemplateOutlet="zoneDisplayTemplate; context: {$implicit: Zone.HealthyCulture}"></ng-container>
        <ng-container *ngTemplateOutlet="zoneDisplayTemplate; context: {$implicit: Zone.FinanceAndOwnership}"></ng-container>

        <ng-container *ngTemplateOutlet="globalZoneTemplate"></ng-container>
    </div>
</ng-template>

<ng-template #zoneDisplayTemplate
             let-zone>
    <adapt-tab-styled-dashboard-element class="m-2"
                                        [attr.data-test]="'zone-' + zone"
                                        [borderColour]="ZoneMetadata.getZoneColour(zone)"
                                        [backgroundColour]="ZoneMetadata.getZoneColour(zone, ZoneColorWeight.TWENTY_FIVE)">
        <div header
             class="d-flex align-items-center justify-content-between">
            <adapt-zone-label [applyZoneColor]="true"
                              [zone]="zone"></adapt-zone-label>
            <div *ngIf="zoneMenuTemplate as menuTemplate"
                 class="ms-auto">
                <ng-container [ngTemplateOutlet]="menuTemplate"
                              [ngTemplateOutletContext]="{$implicit: zone}"></ng-container>
            </div>
        </div>
        <div body
             class="zone-element"
             [ngClass]="getZoneClass(zone)">
            <ng-container class="p-1"
                          [ngTemplateOutlet]="zoneContentContainerTemplate"
                          [ngTemplateOutletContext]="{$implicit: zone}"></ng-container>
        </div>
    </adapt-tab-styled-dashboard-element>
</ng-template>

<ng-template #globalZoneTemplate>
    <div class="global-zone zone"
         *ngIf="showGlobalZoneLocation">
        <div class="zone-header global-zone-header">{{ globalZoneLocationLabel }}</div>
        <div class="zone-element global-zone-element">
            <ng-container [ngTemplateOutlet]="globalZoneContentContainerTemplate"></ng-container>
        </div>
    </div>
</ng-template>
