<adapt-dialog [maxWidth]="900"
              [padding]="false">
    <h3 adapt-dialog-title>{{getTitlePrefix}} role: {{data.role.label}}</h3>

    <dx-tab-panel adapt-dialog-content
                  class="configure-role-tabs"
                  tabsPosition="left"
                  [items]="items">
        <div *dxTemplate="let item of 'detailTemplate'">
            <h2>{{item.title}}</h2>

            <div>
                <label>Name</label>
                <dx-text-box [(value)]="data.role.label"
                             (onValueChanged)="onRoleLabelChanged()"
                             placeholder="Enter a name"
                             [disabled]="isSystemAllocatedRole || isRoleAccessReadOnly"
                             data-test="role-name-input"
                             [adaptValidateEntity]="data.role"
                             adaptValidateEntityProperty="label"
                             #focus>
                </dx-text-box>
            </div>

            <div class="mt-3"
                 *ngIf="!isSystemAllocatedRole">
                <label>Labels</label>
                <adapt-select-label-for-related-entity [role]="data.role"
                                                       (entitiesChange)="changedLabelLocations = $event">
                </adapt-select-label-for-related-entity>
            </div>

            <div *ngIf="data.allowRoleConnectionTypeSelection"
                 class="mt-3">
                <label>Type</label>
                <dx-select-box [items]="connectionTypes"
                               [value]="data.role.connectionType"
                               (valueChange)="onConnectionTypeChanged($event)"></dx-select-box>
            </div>
        </div>

        <div *dxTemplate="let item of 'propertyTemplate'">
            <h2>{{item.title}}</h2>
            <div class="alert alert-guidance">{{getGuidance(item.key)}}</div>
            <div *ngIf="data.role?.supplementaryData">
                <adapt-html-editor [contents]="getSupplementaryData(item.key)"
                                   (contentsChange)="setSupplementaryData(item.key, $event)"
                                   (isValidChange)="item.isEditorValid = $event"></adapt-html-editor>
            </div>
        </div>

        <div *dxTemplate="let item of 'peopleTemplate'">
            <div *ngIf="readonlyPeopleTabMessage"
                 class="alert alert-info">{{readonlyPeopleTabMessage}}</div>
            <adapt-role-people-panel class="people-panel"
                                     [role]="data.role"></adapt-role-people-panel>
        </div>

        <div *dxTemplate="let item of 'editPeopleTemplate'">
            <h2>{{item.title}}</h2>
            <adapt-edit-role-connections [role]="data.role"
                                         (roleConnectionChanged)="onRoleConnectionChanged($event)"
                                         (roleConnectionCreated)="onRoleConnectionChanged($event)"
                                         (roleConnectionDeletion)="onRoleConnectionChanged($event)"></adapt-edit-role-connections>
        </div>

        <div *dxTemplate="let item of 'systemsTemplate'">
            <h2>{{item.title}}</h2>
            <adapt-edit-system-locations [role]="data.role"
                                         (entitiesChange)="onSystemEntitiesChanged($event)"
                                         (creatingAndAddingChange)="onCreatingAndAddingSystemChanged($event)">
            </adapt-edit-system-locations>
        </div>
    </dx-tab-panel>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="isInvalid"
                                   (saveClick)="ok()"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>
