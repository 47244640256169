import { Injectable, Injector } from "@angular/core";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { GuidanceMaterial } from "@common/ADAPT.Common.Model/organisation/guidance-material";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { ProcessStepGuidanceMaterialBreezeModel } from "@common/ADAPT.Common.Model/organisation/process-step-guidance-material";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { SystemEntity } from "@common/ADAPT.Common.Model/organisation/system-entity";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { CommonIntegratedArchitectureFrameworkAuthService } from "@org-common/lib/architecture/common-integrated-architecture-framework-auth.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { SystemisationService } from "app/features/systemisation/systemisation.service";
import { forkJoin, from, lastValueFrom } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { ProcessMapService } from "../process-map/process-map.service";

const AllGuidanceMaterialsSystemComponentsPrimingKey = "AllGuidanceMaterialsSystemComponentsPrimingKey";

@Injectable({
    providedIn: "root",
})
export class IntegratedArchitectureFrameworkAuthService {
    public static ReadTier2 = CommonIntegratedArchitectureFrameworkAuthService.ReadTier2;
    public static EditAnyTier2 = CommonIntegratedArchitectureFrameworkAuthService.EditAnyTier2;
    public static ConfigureAnyTier2 = CommonIntegratedArchitectureFrameworkAuthService.ConfigureAnyTier2;
    public static EditRoleContents = CommonIntegratedArchitectureFrameworkAuthService.EditRoleContents;

    public static EditTier2 = CommonIntegratedArchitectureFrameworkAuthService.EditTier2;
    public static ConfigureTier2 = CommonIntegratedArchitectureFrameworkAuthService.ConfigureTier2;

    public constructor(
        private commonArchAuthService: CommonIntegratedArchitectureFrameworkAuthService,
        private authorisationService: AuthorisationService,
        private processMapService: ProcessMapService,
        private systemService: SystemisationService,
        private teamsService: CommonTeamsService,
        private commonDataService: CommonDataService,
    ) {
    }

    public static registerAccessVerifiers(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            CommonIntegratedArchitectureFrameworkAuthService.EditTier2, // merge this one with the above after getting rid of
            // arch v1
            {
                checkAuthServiceImplementationWithEntity: (injector: Injector) => {
                    const authService = injector.get(IntegratedArchitectureFrameworkAuthService);
                    return authService.personCanEditTier2;
                },
            },
        );
        authorisationService.registerAccessVerifier(
            CommonIntegratedArchitectureFrameworkAuthService.ConfigureTier2,
            {
                checkAuthServiceImplementationWithEntity: (injector: Injector) => {
                    const authService = injector.get(CommonIntegratedArchitectureFrameworkAuthService);
                    return authService.personCanConfigureTier2;
                },
            },
        );
    }


    public async canEditRoleContents(role: Role) {
        // currentPersonCanEntity has return type of boolean | Promise -> so declare this function as async to let both wrapped to a promise
        return this.commonArchAuthService.currentPersonCanEntity<Role>(this.personCanEditRoleContents, role);
    }

    @Autobind
    public async personCanEditRoleContents(person: Person, role?: Role) {
        const canEditTier2 = await this.personCanEditTier2(person);
        const canEditTier1 = this.authorisationService.personHasPermission(person, FeaturePermissionName.ArchitectureTier1Edit);
        const isAccessManager = this.authorisationService.personHasPermission(person, FeaturePermissionName.OrganisationAccessManagementConfigure);
        if (canEditTier2 || canEditTier1 || isAccessManager) {
            return true;
        } else if (role) {
            const canEditOwnRole = this.authorisationService.personHasPermission(person, FeaturePermissionName.ArchitectureTier2PersonalEdit);
            if (canEditOwnRole) {
                return !!role.roleConnections.find((rc) => rc.connectionId === person.getLatestConnection()?.connectionId);
            }
        }

        return false;
    }

    @Autobind
    public async personCanEditTier2(person: Person, system?: SystemEntity) {
        if (system) {
            if (system.ownerId === person.personId) {
                return true; // owner can edit
            }

            // make sure we have teamSystems primed
            await lastValueFrom(this.systemService.primeLocationsForSystem(system.systemEntityId));

            // check if have tier2 edit for any teams the system is within
            if (system.systemLocations.some((sl) => this.authorisationService.personHasPermission(person, FeaturePermissionName.ArchitectureTier2Edit, sl))
                || this.authorisationService.personHasPermission(person, FeaturePermissionName.ArchitectureTier2Edit)) {
                return true;
            }

            // check systemLocations contains role the person can edit
            const personCanEditPromises = await Promise.all(system.systemLocations
                .filter((sl) => sl.role)
                .map((sl) => this.personCanEditRoleContents(person, sl.role)));
            return personCanEditPromises.some((canEdit) => canEdit);
        }

        // check global tier2 edit
        return this.authorisationService.personHasPermission(person, FeaturePermissionName.ArchitectureTier2Edit);
    }


    public async personCanEditProcessMap(processMap: ProcessMap) {
        return this.personCanEditProcessMapId(processMap.processMapId);
    }

    // This encompassing key will only be passed in when evaluating peronCanEditGuidanceMaterial, which will only be called from
    // the guidance material page. Since that page has all GMs, there is already a single query priming for all GMs.
    // There won't be any encompassing key when called from personCanEditProcessMap above as that can be called from multiple places
    // in process-steps.. components which may only be for that single system - no prime for all required.
    private async personCanEditProcessMapId(processMapId: number, encompassingKey?: string) {
        const systemComponent = await lastValueFrom(this.systemService.getSystemComponentForProcessMap(processMapId, encompassingKey));
        // even though there won't be an emit if there is no system component for the process map, converting this to a promise
        // will result in returning an undefined
        if (systemComponent) {
            return this.commonArchAuthService.currentPersonCanEntity<SystemEntity>(this.personCanEditTier2, systemComponent.system);
        } else {
            return false;
        }
    }


    // this will be called from guidance-materials-page first before evaluating permissions
    public primeAuthDataForGuidanceMaterials() {
        return this.commonDataService.getWithOptions(ProcessStepGuidanceMaterialBreezeModel, ProcessStepGuidanceMaterialBreezeModel.identifier, {
            navProperty: "processStep",
        }).pipe(
            switchMap((processStepGuidanceMaterials) => {
                const processMapIds = processStepGuidanceMaterials
                    .map((i) => i.processStep?.processMapId)
                    .filter((id) => !!id) as number[];

                return this.systemService.primeSystemComponentsForProcessMapIds(ArrayUtilities.distinct(processMapIds), AllGuidanceMaterialsSystemComponentsPrimingKey);
            }),
        );
    }

    // this is only called by guidance-materials-page.component - so that will cover all guidance material
    public personCanEditGuidanceMaterial(guidanceMaterial: GuidanceMaterial) {
        return this.processMapService.getProcessStepGuidanceMaterialsByGuidanceMaterialId(guidanceMaterial.guidanceMaterialId).pipe(
            switchMap((processStepGuidanceMaterials) => {
                if (processStepGuidanceMaterials.length === 0) {
                    return this.personCanEditTier2(this.authorisationService.currentPerson!);
                }

                const processMapIds = processStepGuidanceMaterials
                    .map((processStepGuidanceMaterial) => processStepGuidanceMaterial.processStep?.processMapId)
                    .filter((id) => !!id);

                return forkJoin(processMapIds.map((id) => this.personCanEditProcessMapId(id!, AllGuidanceMaterialsSystemComponentsPrimingKey))).pipe(
                    map((results) => results.some((res) => res)),
                );
            }),
        );
    }

    public getConfigurableTeams() {
        return from(this.teamsService.promiseToGetAllActiveTeams()).pipe(
            map((teams) => teams.filter((team) => this.commonArchAuthService.currentPersonCanEntity<Team>(this.commonArchAuthService.personCanConfigureTier2, team))),
        );
    }
}
