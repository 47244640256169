import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { PersonalConfigurationPageComponent } from "./personal-configuration-page.component";

export const personalConfigurationPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-personal-configuration-page", PersonalConfigurationPageComponent)
    .atOrganisationUrl("/people/configure")
    .withTitle("My Configuration")
    .withSearchKeyword("Configure Kanban Boards")
    .withSearchKeyword("Configure Action Boards")
    .withSearchKeyword("Configure Work Boards")
    .withSearchKeyword("Configure Stewardship Activity")
    .build();
