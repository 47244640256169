import { Component, Inject } from "@angular/core";
import { Product } from "@common/ADAPT.Common.Model/organisation/product";
import { ValueStreamProduct } from "@common/ADAPT.Common.Model/organisation/value-stream-product";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { ProductCatalogueService } from "app/features/product/product-catalogue/product-catalogue.service";
import { forkJoin, Observable } from "rxjs";
import { switchMap, tap } from "rxjs/operators";

@Component({
    selector: "adapt-merge-products-dialog",
    templateUrl: "./merge-products-dialog.component.html",
})
export class MergeProductsDialogComponent extends BaseDialogComponent<Product[], boolean> {
    public readonly dialogName = "MergeProductsDialog";

    public destinationProduct?: Product;
    public primeValueStreamProducts$: Observable<any>;

    constructor(
        @Inject(ADAPT_DIALOG_DATA) public products: Product[],
        private productCatalogueService: ProductCatalogueService,
    ) {
        super();

        this.primeValueStreamProducts$ = forkJoin(products.map((i) => productCatalogueService.getValueStreamProductsForProduct(i)));
    }

    @Autobind
    public merge() {
        const affectedValueStreamProducts = this.products
            .reduce((flat, toFlatten) => flat.concat(toFlatten.valueStreamProducts), [] as ValueStreamProduct[]);

        return this.productCatalogueService.mergeProducts(
            this.products,
            this.destinationProduct!,
            affectedValueStreamProducts,
        ).pipe(
            switchMap((entities) => this.productCatalogueService.saveEntities(entities)),
            tap(() => this.resolve(true)),
        );
    }
}
