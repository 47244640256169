import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, ViewChild } from "@angular/core";
import { ProcessMap } from "@common/ADAPT.Common.Model/organisation/process-map";
import { ProcessStep } from "@common/ADAPT.Common.Model/organisation/process-step";
import { SystemComponent } from "@common/ADAPT.Common.Model/organisation/system-component";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { systemPageRoute } from "app/features/systemisation/system-page/system-page.route";
import { SystemisationService } from "app/features/systemisation/systemisation.service";
import { EMPTY, lastValueFrom, Observable } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { ProcessMapService } from "../process-map.service";
import { SelectProcessMapComponent } from "../select-process-map/select-process-map.component";

enum PostCopyAction {
    GoToNewLocation,
    StayHere,
}

@Component({
    selector: "adapt-copy-process-step-dialog",
    templateUrl: "./copy-process-step-dialog.component.html",
})
export class CopyProcessStepDialogComponent extends BaseDialogComponent<ProcessStep, SystemComponent> {
    public readonly dialogName = "CopyProcessStep";

    public readonly PostCopyAction = PostCopyAction;

    public isSelecting = true;
    public isSaving = false;
    public selectedProcessMap?: ProcessMap;
    public childSteps$: Observable<ProcessStep[]>;
    public existingSystemComponent?: SystemComponent;
    public changedEntities: IBreezeEntity[] = [];

    @ViewChild(SelectProcessMapComponent) private selectProcessMapComponent?: SelectProcessMapComponent;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public processStep: ProcessStep,
        private commonDataService: CommonDataService,
        private processMapService: ProcessMapService,
        private systemisationService: SystemisationService,
    ) {
        super();

        this.childSteps$ = this.processMapService.getChildSteps(processStep);
        this.systemisationService.getSystemComponentForProcessMap(processStep.processMapId).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((component) => this.existingSystemComponent = component);
    }

    public get isCreatingProcessMap() {
        return this.selectProcessMapComponent?.isCreating;
    }

    @Autobind
    public copyAndSave() {
        this.isSaving = true;
        if (this.selectedProcessMap) {
            return this.systemisationService.copyAndSaveProcessStep(this.processStep.processStepId, this.selectedProcessMap.processMapId).pipe(
                switchMap(() => this.systemisationService.getSystemComponentForProcessMap(this.selectedProcessMap!.processMapId)),
                tap((destinationSystemComponent) => {
                    this.isSaving = false;
                    this.isSelecting = false;
                    if (destinationSystemComponent.systemEntityId === this.existingSystemComponent?.systemEntityId) {
                        this.resolve(destinationSystemComponent);
                    }
                }),
                catchError((err: HttpErrorResponse) => {
                    this.isSaving = false;
                    this.setErrorMessage(err.message);
                    return EMPTY;
                }),
            );
        }

        return EMPTY;
    }

    @Autobind
    public async postCopy(action: PostCopyAction) {
        if (action === PostCopyAction.GoToNewLocation) {
            await lastValueFrom(this.systemisationService.getSystemComponentForProcessMap(this.selectedProcessMap!.processMapId).pipe(
                switchMap((systemComponent) => systemPageRoute.gotoRoute(
                    { systemEntityId: systemComponent.systemEntityId },
                    { addedComponentId: systemComponent.systemComponentId })),
            ));
        } else {
            this.resolve(await lastValueFrom(this.systemisationService.getSystemComponentForProcessMap(this.selectedProcessMap!.processMapId)));
        }
    }

    public cancel() {
        if (this.changedEntities.length > 0) {
            this.commonDataService.rejectChanges(this.changedEntities)
                .subscribe(() => super.cancel());
        } else {
            super.cancel();
        }
    }
}
