<adapt-dashboard-element name="embedADAPT access"
                         *ngIf="(latestConnection$ | async) as latestConnection">

    <div class="badge bg-danger"
         *ngIf="!latestConnection.hasAccess"
         adaptTooltip="Set the ability for this person to log in from page menu or on the 'Configuration' -> 'Manage People' page">
        <i class="fal fa-user-times pe-1"></i>Login disallowed
    </div>
    <div class="badge bg-warning"
         *ngIf="latestConnection.hasAccess && latestConnection.startDate > today"
         adaptTooltip="The user will be allowed to login from their start date">
        <i class="fal fa-user-times pe-1"></i>Login Pending
    </div>
    <div class="badge bg-success"
         *ngIf="latestConnection.hasAccess && latestConnection.startDate <= today"
         adaptTooltip="Set the ability for this person to log in from page menu or on the 'Configuration' -> 'Manage People' page">
        <i class="fal fa-user-times pe-1"></i>Login allowed
    </div>

    <button class="btn"
            (click)="showDetails = !showDetails"
            data-test="show-access-details">
        <i class="key-result-chevron"
           [ngClass]="chevronIconClass"></i>
    </button>

    <div *ngIf="showDetails">
        <h3 class="mt-2">User type</h3>
        <strong>{{userTypeLabel$ | async}}</strong>: {{userTypeInfo$ | async}}

        <ng-container *adaptLoadingUntilFirstEmit="let roleConnections of roleConnections$">
            <ng-container *ngIf="roleConnections.organisation.length > 0">
                <h3 class="mt-2">Organisation access levels</h3>
                <div *ngFor="let roleConnections of roleConnections.organisation">
                    <adapt-link-access-level [role]="roleConnections.role"></adapt-link-access-level>
                </div>
            </ng-container>

            <ng-container *ngIf="roleConnections.team.length > 0">
                <h3 class="mt-2">Team access levels</h3>
                <div *ngFor="let roleConnections of roleConnections.team">
                    <adapt-link-access-level [role]="roleConnections.role"
                                             [adaptLinkDisabled]="true"></adapt-link-access-level>
                </div>
            </ng-container>

            <ng-container *ngIf="roleConnections.organisation.length === 0 && roleConnections.team.length === 0">
                <h3 class="mt-2">Access levels</h3>
                This person has not been granted any access to embedADAPT
            </ng-container>
        </ng-container>

        <ng-container *adaptLoadingUntilFirstEmit="let inviteDate of inviteDate$">
            <ng-container *ngIf="inviteDate">
                <h3 class="mt-2">Platform invite date</h3>
                {{inviteDate | adaptDate}}
            </ng-container>
        </ng-container>
    </div>
</adapt-dashboard-element>
