import { Component, Inject } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { PersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";

export interface ICatchupHistoryChartDialogData {
    person1: Person;
    person2: Person;
}

@Component({
    selector: "adapt-catchup-history-chart-dialog",
    templateUrl: "./catchup-history-chart-dialog.component.html",
})
@PersistableDialog("CatchupHistoryChartDialog")
export class CatchupHistoryChartDialogComponent extends BaseDialogComponent<ICatchupHistoryChartDialogData, void> {
    public readonly dialogName = "CatchupHistoryChart";

    public title = "";

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public data: ICatchupHistoryChartDialogData,
    ) {
        super();

        this.title = `Peer Catch-up history between ${this.data.person1.fullName} and ${this.data.person2.fullName}`;
    }
}
